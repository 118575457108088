import React from 'react';
import { GoogleLogin as OAuthGoogleLogin } from '@react-oauth/google';

function GoogleLogin(props) {
  // const { text, onCallback, customRenderer, ...rest } = props;
  // return (
  //   <OAuthGoogleLogin
  //     onSuccess={credentialResponse => {
  //       console.log(credentialResponse);
  //     }}
  //     onError={() => {
  //       console.log('Login Failed');
  //     }}
  //   />
  // );
}

export default GoogleLogin;